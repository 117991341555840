export default {
    general: 'Allgemeine Informationen',
    currentVersion: 'Aktuelle Version',
    currentFeatures: 'Neueste Funktionen',
    bugfixes: 'Bugfixes',
    isCurrentVersion: 'Aktuellste Version',
    loadNewestVersion: 'Neueste Version laden',
    isOldVersion: 'Veraltete Version',
    releaseNotes: 'Release Notes'
}
