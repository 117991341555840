import { default as indexHnn7WMq3DWMeta } from "/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/auth/index.vue?macro=true";
import { default as indexpwyVDjN5nGMeta } from "/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/auth/social/[organisation]/[provider]/index.vue?macro=true";
import { default as indexhc2ZkqrnGfMeta } from "/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/index.vue?macro=true";
import { default as organisationBaseDatadxyBCV2uW6Meta } from "/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/organisations/[id]/organisationBaseData.vue?macro=true";
import { default as _91id_93N56vgRoMEfMeta } from "/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/organisations/[id].vue?macro=true";
import { default as indexP0xaGakIy9Meta } from "/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/organisations/index.vue?macro=true";
import { default as languageY55vaG3vzJMeta } from "/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/settings/language.vue?macro=true";
import { default as _91id_93dyeqXiv1eqMeta } from "/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/settings/loginProviders/[id].vue?macro=true";
import { default as index8Dlz15QSAMMeta } from "/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/settings/loginProviders/index.vue?macro=true";
import { default as organisationED63RORMFYMeta } from "/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/settings/organisation.vue?macro=true";
import { default as rolescNVoFhKTnmMeta } from "/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/settings/roles.vue?macro=true";
import { default as settingsopAT0LaH2OMeta } from "/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/settings.vue?macro=true";
import { default as userActivitiesEymHJaVs7tMeta } from "/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/users/[id]/userActivities.vue?macro=true";
import { default as userBaseDataUgNdTNXparMeta } from "/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/users/[id]/userBaseData.vue?macro=true";
import { default as userCommunicationsslpUSbH63YMeta } from "/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/users/[id]/userCommunications.vue?macro=true";
import { default as _91id_93PQHpIKzylGMeta } from "/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/users/[id].vue?macro=true";
import { default as indexElQmw63J5TMeta } from "/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/users/index.vue?macro=true";
import { default as usersMQjnVJgrjOMeta } from "/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/users.vue?macro=true";
import { default as _91_46_46_46slug_93uQvbJr3HymMeta } from "/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/wiki/[...slug].vue?macro=true";
export default [
  {
    name: "auth",
    path: "/auth",
    meta: indexHnn7WMq3DWMeta || {},
    component: () => import("/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-social-organisation-provider",
    path: "/auth/social/:organisation()/:provider()",
    meta: indexpwyVDjN5nGMeta || {},
    component: () => import("/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/auth/social/[organisation]/[provider]/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "organisations-id",
    path: "/organisations/:id()",
    meta: _91id_93N56vgRoMEfMeta || {},
    component: () => import("/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/organisations/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "organisations-id-organisationBaseData",
    path: "organisationBaseData",
    meta: organisationBaseDatadxyBCV2uW6Meta || {},
    component: () => import("/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/organisations/[id]/organisationBaseData.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "organisations",
    path: "/organisations",
    meta: indexP0xaGakIy9Meta || {},
    component: () => import("/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-language",
    path: "language",
    component: () => import("/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/settings/language.vue").then(m => m.default || m)
  },
  {
    name: "settings-loginProviders-id",
    path: "loginProviders/:id()",
    component: () => import("/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/settings/loginProviders/[id].vue").then(m => m.default || m)
  },
  {
    name: "settings-loginProviders",
    path: "loginProviders",
    meta: index8Dlz15QSAMMeta || {},
    component: () => import("/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/settings/loginProviders/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-organisation",
    path: "organisation",
    meta: organisationED63RORMFYMeta || {},
    component: () => import("/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/settings/organisation.vue").then(m => m.default || m)
  },
  {
    name: "settings-roles",
    path: "roles",
    meta: rolescNVoFhKTnmMeta || {},
    component: () => import("/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/settings/roles.vue").then(m => m.default || m)
  }
]
  },
  {
    name: usersMQjnVJgrjOMeta?.name,
    path: "/users",
    meta: usersMQjnVJgrjOMeta || {},
    component: () => import("/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/users.vue").then(m => m.default || m),
    children: [
  {
    name: "users-id",
    path: ":id()",
    meta: _91id_93PQHpIKzylGMeta || {},
    component: () => import("/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/users/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "users-id-userActivities",
    path: "userActivities",
    component: () => import("/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/users/[id]/userActivities.vue").then(m => m.default || m)
  },
  {
    name: "users-id-userBaseData",
    path: "userBaseData",
    meta: userBaseDataUgNdTNXparMeta || {},
    component: () => import("/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/users/[id]/userBaseData.vue").then(m => m.default || m)
  },
  {
    name: "users-id-userCommunications",
    path: "userCommunications",
    component: () => import("/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/users/[id]/userCommunications.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "users",
    path: "",
    meta: indexElQmw63J5TMeta || {},
    component: () => import("/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "wiki-slug",
    path: "/wiki/:slug(.*)*",
    meta: _91_46_46_46slug_93uQvbJr3HymMeta || {},
    component: () => import("/home/forge/betterzone.farbcode.net/releases/20240620094815/client/pages/wiki/[...slug].vue").then(m => m.default || m)
  }
]