export default {
    login: 'Login',
    logout: 'Logout',
    signIn: 'Anmelden',
    stayLoggedIn: 'Angemeldet bleiben',
    forgotPassword: 'Passwort vergessen?',
    registerHere: 'Hier registrieren',
    noAccountQuestion: 'Noch kein Account?',
    pleaseWait: 'Bitte warten',
    register: 'Registrieren',
    registerText: 'Noch keinen Account?',
    registerCallToAction: 'Jetzt registrieren',

    sessionTimeout: 'Ihre Sitzung ist abgelaufen.',
    pleaseLoginAgain: 'Bitte melden Sie sich erneut an.',

    email: 'E-Mail',
    password: 'Passwort',
    repeatPassword: 'Passwort wiederholen',
    savePassword: 'Passwort speichern',
    pleaseEnterNewPassword: 'Bitte geben Sie ein neues Passwort ein.',
    firstname: 'Vorname',
    lastname: 'Nachname',
    back: 'Zurück',
    forgotPasswordText: 'Bitte gebe deine E-Mail-Adresse ein. Wir senden dir dann einen Link zum Zurücksetzen deine Passworts zu.',
    forgotPasswordSuccess: 'Es wurde eine E-Mail zum Zurücksetzen des Passworts an deine E-Mail Adresse gesendet.',
    resetPassword: 'Passwort zurücksetzen',
    resetPasswordSuccessTitle: 'Dein Passwort wurde erfolgreich geändert',
    resetPasswordSuccessText: 'Du kannst dich jetzt mit dem neuen Passwort anmelden.',
    socialRedirecting: 'Sie werden weitergeleitet...',
    socialError: 'Bei der Anmeldung ist ein Fehler aufgetreten.',
    orContinueWith: 'oder weiter mit'
}