import menu from './menu'
import globalSearch from './globalSearch'
import requestLog from './requestLog'
import pageTitle from './pageTitle'
import wiki from './wiki'
import table from './table/index'
import files from './files'
import settings from './settings'
import auth from './auth'
import users from './users/index'
import notifications from './notifications'
import activityLog from './activityLog'
import loginProviders from './loginProviders/index'

export default {
    auth,
    localeHint: 'Dies ist die deutsche Version der Website.',
    canNotAccessRoute: 'Sie haben keine Berechtigung, auf diese Seite zu gehen.',

    serverError: 'Es liegt serverseitig ein Fehler vor.',
    serverErrorSupport: 'Falls sich dieser Fehler wiederholt wenden Sie sich bitte an den Support.',

    invalidRequestData: 'Die Anfrage enthält ungültige Daten.',
    modelDoesNotExist: 'Die angeforderte Ressource existiert nicht.',
    unauthorizedRequest: 'Sie sind nicht berechtigt, diese Seite aufzurufen.',

    baseData: 'Daten',

    resourceDoesNotExist: 'Der aufgerufene Eintrag existiert nicht, oder Sie haben keine Berechtigung diesen abzurufen.',

    abort: 'Abbrechen',
    delete: 'Löschen',
    back: 'Zurück',
    save: 'Speichern',
    discard: 'Verwerfen',
    error: 'Fehler',
    open: 'Öffnen',
    show: 'Anzeigen',
    more: 'Weiteres',
    change: 'Ändern',
    add: 'Hinzufügen',
    welcome: 'Willkommen!',


    locales: {
        de: 'Deutsch',
        en: 'English'
    },

    menu,
    files,
    globalSearch,
    requestLog,
    notifications,
    activityLog,
    pageTitle,
    table,
    settings,
    users,
    loginProviders,
    wiki,

    testUsers: users
}
