export default {
    home: 'Home',
    dashboard: 'Dashboard',
    users: 'Nutzer',
    merchants: 'Händler',
    orders: 'Bestellungen',
    organisations: 'Händler',
    statistics: 'Statistiken',
    settings: 'Einstellungen',
    myProfile: 'Mein Profil',
    information: 'Informationen',
    logout: 'Logout',
    loginProviders: 'Login Methoden'
}