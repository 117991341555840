import actions from './actions'
import tools from './tools/index'
import filter from './filter'

export default {
    overview: 'Übersicht',
    totalEntries: 'Keine Ergebnisse |  Ein Eintrag wurde gefunden | Insgesamt wurden {count} Einträge gefunden',
    labelPerPageOptions: 'Einträge pro Seite',

    actions,
    tools,
    filter
}